export enum AppProgressState {
    None = 'none',
    LoadingAssets = 'LoadingAssets',
    LoggingIn = 'LoggingIn',
    Joining = 'Joining',
    InitializingWorkspace = 'InitializingWorkspace',
    CreatingSpace = 'CreatingSpace',
    CreatingDM = 'CreatingDM',
    CreatingGDM = 'CreatingGDM',
}
